import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import theme from '../../../theme';
import AutocompleteInput from '../../Inputs/Autocomplete';
import NumericTextField from '../../Inputs/NumericTextField';
import TextInput from '../../Inputs/TextInput';

const MemberInformations = ({
  values,
  countries,
  errors,
  touched,
  onChange,
  onBlur,
  setFieldValue,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS',
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <TextInput
          name="memberName"
          error={Boolean(touched.memberName && errors.memberName)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.NAME',
          })}
          helperText={touched.memberName && errors.memberName}
          onChange={onChange}
          value={values.memberName}
          onBlur={onBlur}
        />
        <AutocompleteInput
          name="memberCountry"
          options={countries || []}
          getLabel={(country) => country.pt}
          value={values.memberCountry}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.COUNTRY',
          })}
          error={Boolean(touched.memberCountry && errors.memberCountry)}
          helperText={touched.memberCountry && errors.memberCountry}
          onChange={(newValue) => {
            setFieldValue('memberCountry', newValue);
          }}
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <AutocompleteInput
          name="memberContactPrefix"
          options={countries || []}
          getLabel={(country) => country.country_code}
          value={values.memberContactPrefix}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.CONTACT_PREFIX',
          })}
          error={Boolean(
            touched.memberContactPrefix && errors.memberContactPrefix,
          )}
          helperText={touched.memberContactPrefix && errors.memberContactPrefix}
          onChange={(newValue) => {
            setFieldValue('memberContactPrefix', newValue);
          }}
          onBlur={onBlur}
        />
        <TextInput
          name="memberContact"
          error={Boolean(touched.memberContact && errors.memberContact)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.CONTACT',
          })}
          helperText={touched.memberContact && errors.memberContact}
          onChange={onChange}
          value={values.memberContact}
          onBlur={onBlur}
        />
        <TextInput
          name="memberEmail"
          error={Boolean(touched.memberEmail && errors.memberEmail)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.EMAIL',
          })}
          helperText={touched.memberEmail && errors.memberEmail}
          onChange={onChange}
          value={values.memberEmail}
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <TextInput
          name="memberProfession"
          error={Boolean(touched.memberProfession && errors.memberProfession)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.PROFESSION',
          })}
          helperText={touched.memberProfession && errors.memberProfession}
          onChange={onChange}
          value={values.memberProfession}
          onBlur={onBlur}
        />
        <TextInput
          name="memberJobLocation"
          error={Boolean(touched.memberJobLocation && errors.memberJobLocation)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.JOB_LOCATION',
          })}
          helperText={touched.memberJobLocation && errors.memberJobLocation}
          onChange={onChange}
          value={values.memberJobLocation}
          onBlur={onBlur}
        />
        <NumericTextField
          name="memberMonthlyAmount"
          error={Boolean(
            touched.memberMonthlyAmount && errors.memberMonthlyAmount,
          )}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.MONTHLY_AMOUNT',
          })}
          helperText={touched.memberMonthlyAmount && errors.memberMonthlyAmount}
          onChange={onChange}
          value={values.memberMonthlyAmount}
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 2,
          gap: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <NumericTextField
          name="memberNIF"
          error={Boolean(touched.memberName && errors.memberName)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.MEMBER_INFORMATIONS.NIF',
          })}
          helperText={touched.memberNIF && errors.memberNIF}
          onChange={onChange}
          value={values.memberNIF}
          onBlur={onBlur}
        />
      </Box>
    </Box>
  );
};

MemberInformations.propTypes = {
  values: PropTypes.shape({
    memberName: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  errors: PropTypes.shape({
    memberName: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    memberName: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default MemberInformations;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import rawAxios from 'axios';

import axios from '../lib/axios';
import { getFileExtension } from '../utils/getFileExtension';

const initialState = {
  request: null,
  isLoading: false,
  error: null,
  success: null,
};

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    _createRequest: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    _createRequestSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    },
    _createRequestFailure: (state) => {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    },
    _clearSuccess: () => {
      return {
        error: null,
        success: null,
      };
    },
  },
});

const {
  _clearSuccess,
  _createRequest,
  _createRequestSuccess,
  _createRequestFailure,
} = slice.actions;

export default slice.reducer;

export const clearSuccess = createAsyncThunk(
  'request/clear_success',
  async (id, { dispatch }) => {
    dispatch(_clearSuccess());
  },
);

export const create = createAsyncThunk(
  'request/create_request',
  async (data, { dispatch }) => {
    try {
      dispatch(_createRequest());

      const body = {
        name: data.memberName,
        profession: data.memberProfession,
        job_location: data.memberJobLocation,
        contact_prefix: data.memberContactPrefix.country_code,
        contact: data.memberContact,
        email: data.memberEmail,
        monthly_amount: data.memberMonthlyAmount,
        nif: data.memberNIF.toString(),
        country_id: data.memberCountry.id,
      };

      const orderResponse = await axios.post('/members', body);
      const { id } = orderResponse.data;

      if (data.file) {
        const presignedURLResponse = await axios.get(
          `/members/${id}/avatar/presigned_url?file_extension=${getFileExtension(data.file)}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          },
        );

        const { url } = presignedURLResponse.data;

        await rawAxios.put(url, data.file);
      }

      dispatch(_createRequestSuccess());
    } catch (error) {
      dispatch(_createRequestFailure());
    }
  },
);

import NotFound from './components/NotFound';
import FormRequest from './pages/FormRequest';
import SuccessSubmit from './pages/SuccessSubmit';

const routes = [
  {
    path: '/',
    element: <FormRequest />,
  },
  {
    path: '/sucesso',
    element: <SuccessSubmit />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;

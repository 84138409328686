import { CloudCircle, RemoveCircle } from '@mui/icons-material';
import {
  Box,
  Typography,
  Input,
  Button,
  List,
  ListItem,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import theme from '../../../theme';
import ContentCard from '../../ContentCard';

const UploadDocuments = ({ values, selectedFile, setSelectedFile }) => {
  const intl = useIntl();

  const [filePreviews, setFilePreviews] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
    setFilePreviews(file);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFilePreviews(null);
  };

  const renderFilePreviews = () => {
    if (filePreviews === null) {
      return null;
    }

    return (
      <Box>
        <ContentCard>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body1">
                {intl.formatMessage({
                  id: 'REQUEST_FORM.UPLOAD_DOCUMENTS.UPLOADED_DOCUMENTS',
                })}
              </Typography>
            </Box>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ListItem
                key={filePreviews}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: 'none',
                  py: 1,
                }}
              >
                <Typography overflow="hidden" variant="body3">
                  {filePreviews.name}
                </Typography>
                <IconButton onClick={() => handleRemoveFile(selectedFile)}>
                  <RemoveCircle
                    fontSize="small"
                    sx={{ color: theme.palette.error.main }}
                  />
                </IconButton>
              </ListItem>
            </List>
          </Box>
        </ContentCard>
      </Box>
    );
  };

  return (
    <Box>
      <Box>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'REQUEST_FORM.UPLOAD_DOCUMENTS',
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <Button
          component="label"
          variant="outlined"
          startIcon={<CloudCircle />}
          sx={{ position: 'relative' }}
        >
          {intl.formatMessage({
            id: 'REQUEST_FORM.UPLOAD_DOCUMENTS.UPLOAD_BUTTON',
          })}
          <Input
            sx={{
              height: 0,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'absolute',
              left: 0,
              right: 0,
            }}
            type="file"
            inputProps={{
              multiple: false,
              accept: 'image/*', // This restricts to image files
            }}
            onChange={handleFileChange}
            value={values.files}
          />
        </Button>
        {renderFilePreviews()}
      </Box>
    </Box>
  );
};

UploadDocuments.propTypes = {
  values: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  selectedFile: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelectedFile: PropTypes.func.isRequired,
};

export default UploadDocuments;

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Toolbar,
  AppBar,
  Typography,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import DesktopLogo from '../../assets/header/logo_horizontal_clube_sportivo_mindelense.png';
import { SUPPORTED_LANGUAGES } from '../../utils/constants';
import { setLanguageOnLocalStorage } from '../../utils/languagesFunctions';

const Header = ({ changeLanguageOnLocale, language }) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const getFlag = (countryCode) => {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  };

  const getButtonLabel = (lang) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <Typography>{getFlag(SUPPORTED_LANGUAGES[lang].iso)}</Typography>
        <Typography
          sx={{
            display: 'flex',
            '@media (max-width: 600px)': {
              display: 'none',
            },
          }}
        >
          {SUPPORTED_LANGUAGES[lang].label}
        </Typography>
      </Box>
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(language);
  const [buttonLabel, setButtonLabel] = useState(
    getButtonLabel(selectedLang, mobileDevice),
  );

  const handleOpenMenu = (event) => {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    setButtonLabel(getButtonLabel(selectedLang));
    setLanguageOnLocalStorage(selectedLang);
    changeLanguageOnLocale(selectedLang);
    handleCloseMenu();
  }, [changeLanguageOnLocale, selectedLang]);

  return (
    <Box>
      <AppBar position="fixed" color="white">
        <Toolbar
          sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}
        >
          {mobileDevice ? (
            <img src={DesktopLogo} alt="Mobile Logo" width="160" />
          ) : (
            <img src={DesktopLogo} alt="Desktop Logo" width="200" />
          )}
          <Button
            id="language-selection-button"
            color="white"
            variant="contained"
            size="small"
            onClick={handleOpenMenu}
            endIcon={<ArrowDropDownIcon />}
            sx={{
              py: 0,
              borderRadius: 5,
              textTransform: 'none',
            }}
          >
            {buttonLabel}
          </Button>
          <Menu
            id="languages-menu"
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleCloseMenu}
          >
            {Object.keys(SUPPORTED_LANGUAGES).map((lang) => (
              <MenuItem key={lang} onClick={() => setSelectedLang(lang)}>
                {getButtonLabel(lang)}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
};

Header.propTypes = {
  changeLanguageOnLocale: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default Header;

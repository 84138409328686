import { Box, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../components/Button/CustomButton';
import ContentCard from '../../components/ContentCard';
import CustomSnackBar from '../../components/CustomSnackBar';
import Information from '../../components/Forms/MemberInformations';
import UploadDocuments from '../../components/Forms/UploadDocuments';
import SectionHeader from '../../components/SectionHeader';
import Wrapper from '../../components/Wrapper';
import useSchemaValidation from '../../hooks/useSchemaValidation';
import { fetch } from '../../slices/countries';
import { create as createRequest, clearSuccess } from '../../slices/request';
import { useDispatch } from '../../store';
import getCombinedValidationSchema from '../../yupValidation';

const FormRequest = () => {
  let formikRef;
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showToastError, setShowToastError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const { isLoading, success, error } = useSelector((state) => state.request);
  const { countries } = useSelector((state) => state.countries);

  const {
    memberNameValidationSchema,
    memberProfessionValidationSchema,
    memberJobLocationValidationSchema,
    memberContactValidationSchema,
    memberEmailValidationSchema,
    memberMonthlyAmountValidationSchema,
    memberNIFValidationSchema,
    memberContactPrefixValidationSchema,
    memberCountryValidationSchema,
  } = useSchemaValidation();

  const initialValues = {
    memberName: undefined,
    memberProfession: undefined,
    memberJobLocation: undefined,
    memberContact: undefined,
    memberEmail: undefined,
    memberMonthlyAmount: undefined,
    memberNIFAmount: undefined,
    memberContactPrefix: undefined,
    memberCountry: undefined,
    file: null,
  };

  const combinedValidationSchema = getCombinedValidationSchema([
    memberNameValidationSchema(),
    memberProfessionValidationSchema(),
    memberJobLocationValidationSchema(),
    memberContactValidationSchema(),
    memberEmailValidationSchema(),
    memberMonthlyAmountValidationSchema(),
    memberNIFValidationSchema(),
    memberContactPrefixValidationSchema(),
    memberCountryValidationSchema(),
  ]);

  const handleSubmitForm = async (data) => {
    await dispatch(createRequest(data));
  };

  const handleExternalSubmit = () => {
    if (formikRef) {
      formikRef.handleSubmit();
    }
  };

  useEffect(() => {
    dispatch(fetch());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      navigate('/sucesso');
      dispatch(clearSuccess());
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (error) {
      setShowToastError(true);
    }
  }, [error, dispatch]);

  const handleCloseToast = () => {
    setShowToastError(false);
  };

  const renderForm = () => {
    if (countries) {
      return (
        <>
          <SectionHeader
            title={intl.formatMessage({
              id: 'HOME.FORM_REQUEST_TITLE',
            })}
            description={intl.formatMessage({
              id: 'HOME.FORM_REQUEST_DESCRIPTION',
            })}
            isPrimary
          />
          <Box sx={{ mt: 3 }}>
            <ContentCard>
              <Box>
                <Formik
                  /* eslint-disable no-return-assign */
                  innerRef={(ref) => (formikRef = ref)}
                  initialValues={initialValues}
                  validationSchema={combinedValidationSchema}
                  onSubmit={(values) => {
                    const newValues = { ...values, file: selectedFile };
                    handleSubmitForm(newValues);
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 3,
                          flexDirection: 'column',
                          p: 3,
                        }}
                      >
                        <Information
                          setFieldValue={setFieldValue}
                          countries={countries}
                          values={values}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <UploadDocuments
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                          handleFileChange={handleChange}
                          values={values}
                        />
                        <Box sx={{}}>
                          <CustomButton
                            onClick={handleExternalSubmit}
                            title={intl.formatMessage({
                              id: 'ACTIONS.SUBMIT',
                            })}
                            isLoading={isLoading}
                          />
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </ContentCard>
          </Box>
          <CustomSnackBar
            open={showToastError}
            severity="error"
            message="Ocorreu um erro."
            onClose={handleCloseToast}
          />
        </>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Formulário Sócio | Mindelense</title>
      </Helmet>
      <Wrapper>{renderForm()}</Wrapper>
    </>
  );
};

export default FormRequest;

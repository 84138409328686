const getFileExtension = (file) => {
  if (!file) {
    return null;
  }
  const fileNameParts = file.name.split('.');
  if (
    fileNameParts.length === 1 ||
    (fileNameParts[0] === '' && fileNameParts.length === 2)
  ) {
    return null;
  }
  return fileNameParts.pop().toLowerCase();
};

module.exports = {
  getFileExtension,
};

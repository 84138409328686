import { combineReducers } from '@reduxjs/toolkit';

import countries from '../slices/countries';
import request from '../slices/request';

const appReducer = combineReducers({
  request,
  countries,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

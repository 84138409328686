import { useIntl } from 'react-intl';
import * as Yup from 'yup';

const useSchemaValidation = () => {
  const intl = useIntl();

  const COMMON_MESSAGE = {
    required: 'YUP.VALIDATION.COMMON.REQUIRED',
    number: 'YUP.VALIDATION.COMMON.NUMBER',
    maxCharacters: 'YUP.VALIDATION.COMMON.MAX_CHARACTERS',
  };

  const EMAIL_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
    email: intl.formatMessage({
      id: 'YUP.VALIDATION.VALID_EMAIL',
    }),
  };

  const MEMBER_NAME_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
    maxCharacters: intl.formatMessage({
      id: COMMON_MESSAGE.maxCharacters,
    }),
  };

  const MEMBER_PROFESSION_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
    maxCharacters: intl.formatMessage({
      id: COMMON_MESSAGE.maxCharacters,
    }),
  };

  const MEMBER_JOB_LOCATION_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
    maxCharacters: intl.formatMessage({
      id: COMMON_MESSAGE.maxCharacters,
    }),
  };

  const MEMBER_CONTACT_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
    maxCharacters: intl.formatMessage({
      id: COMMON_MESSAGE.maxCharacters,
    }),
  };

  const MEMBER_EMAIL_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
    maxCharacters: intl.formatMessage({
      id: COMMON_MESSAGE.maxCharacters,
    }),
  };

  const MEMBER_MONTHLY_AMOUNT_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
    maxCharacters: intl.formatMessage({
      id: COMMON_MESSAGE.maxCharacters,
    }),
  };

  const MEMBER_CONTACT_PREFIX_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
  };

  const MEMBER_COUNTRY_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
  };

  const MEMBER_NIF_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: COMMON_MESSAGE.required,
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS.NIF',
    }),
  };

  const memberNameValidationSchema = () =>
    Yup.object().shape({
      memberName: Yup.string()
        .max(255, MEMBER_NAME_ERROR_MESSAGES.maxCharacters)
        .required(MEMBER_NAME_ERROR_MESSAGES.required)
        .nullable(),
    });

  const memberProfessionValidationSchema = () =>
    Yup.object().shape({
      memberProfession: Yup.string()
        .max(255, MEMBER_PROFESSION_ERROR_MESSAGES.maxCharacters)
        .required(MEMBER_PROFESSION_ERROR_MESSAGES.required)
        .nullable(),
    });

  const memberJobLocationValidationSchema = () =>
    Yup.object().shape({
      memberJobLocation: Yup.string()
        .max(255, MEMBER_JOB_LOCATION_ERROR_MESSAGES.maxCharacters)
        .required(MEMBER_JOB_LOCATION_ERROR_MESSAGES.required)
        .nullable(),
    });

  const memberContactValidationSchema = () =>
    Yup.object().shape({
      memberContact: Yup.string()
        .max(255, MEMBER_CONTACT_ERROR_MESSAGES.maxCharacters)
        .required(MEMBER_CONTACT_ERROR_MESSAGES.required)
        .nullable(),
    });

  const memberEmailValidationSchema = () =>
    Yup.object().shape({
      memberEmail: Yup.string()
        .max(255, MEMBER_EMAIL_ERROR_MESSAGES.maxCharacters)
        .required(MEMBER_EMAIL_ERROR_MESSAGES.required)
        .email(EMAIL_ERROR_MESSAGES.email)
        .nullable(),
    });

  const memberMonthlyAmountValidationSchema = () =>
    Yup.object().shape({
      memberMonthlyAmount: Yup.string()
        .max(255, MEMBER_MONTHLY_AMOUNT_ERROR_MESSAGES.maxCharacters)
        .required(MEMBER_MONTHLY_AMOUNT_ERROR_MESSAGES.required)
        .nullable(),
    });

  const memberNIFValidationSchema = () =>
    Yup.object().shape({
      memberNIF: Yup.string()
        .max(9, MEMBER_NIF_ERROR_MESSAGES.maxCharacters)
        .required(MEMBER_NIF_ERROR_MESSAGES.required)
        .nullable(),
    });

  const memberContactPrefixValidationSchema = () =>
    Yup.object().shape({
      memberContactPrefix: Yup.object()
        .required(MEMBER_CONTACT_PREFIX_ERROR_MESSAGES.required)
        .nullable(),
    });

  const memberCountryValidationSchema = () =>
    Yup.object().shape({
      memberCountry: Yup.object()
        .required(MEMBER_COUNTRY_ERROR_MESSAGES.required)
        .nullable(),
    });

  return {
    memberNameValidationSchema,
    memberProfessionValidationSchema,
    memberJobLocationValidationSchema,
    memberContactValidationSchema,
    memberEmailValidationSchema,
    memberMonthlyAmountValidationSchema,
    memberNIFValidationSchema,
    memberContactPrefixValidationSchema,
    memberCountryValidationSchema,
  };
};

export default useSchemaValidation;

import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';

import animationData from '../../assets/lottiefiles/success.json';
import CustomButton from '../../components/Button/CustomButton';
import SectionHeader from '../../components/SectionHeader';
import Wrapper from '../../components/Wrapper';

const SuccessSubmit = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  const handleNavigateToCreateRequest = () => {
    navigate('/');
  };

  return (
    <>
      <Helmet>
        <title>Submetida com Sucesso | Mindelense</title>
      </Helmet>
      <Wrapper>
        <SectionHeader
          title={intl.formatMessage({
            id: 'REQUEST_FORM.SUCCESS.TITLE',
          })}
          description={intl.formatMessage({
            id: 'REQUEST_FORM.SUCCESS.DESCRIPTION',
          })}
          isPrimary
        />
        <Container maxWidth="sm">
          <Box sx={{ mt: 3 }}>
            <Lottie
              speed={1}
              options={defaultOptions}
              height={350}
              width={350}
            />
            <CustomButton
              onClick={() => handleNavigateToCreateRequest()}
              title={intl.formatMessage({
                id: 'REQUEST_FORM.NEW_SUBMITTION',
              })}
            />
          </Box>
        </Container>
      </Wrapper>
    </>
  );
};

export default SuccessSubmit;
